/** @jsx jsx */
import { jsx, Text, Grid } from 'theme-ui'
import { observer } from 'mobx-react'

import { useStore } from '@stores/useStore'
import { GameState } from '@stores/models/Game'
import { Button } from '@components/Button'

export const CanvasIdleState = observer(() => {
  const room = useStore()
  const { game } = room

  return game.state !== GameState.IDLE ? null : (
    <Grid
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 3,
        bg: 'rgba(255,255,255,0.75)',
        backdropFilter: 'blur(8px)'
      }}
    >
      <Grid
        sx={{
          justifyContent: 'center',
          gridRowGap: 3,
          textAlign: 'center',
          p: 4
        }}
      >
        <Text>
          {!room.isEmpty && room.everybodyReady
            ? 'Iedereen is er klaar voor, nu jij nog!'
            : room.isEmpty
            ? 'Liever in je eentje spelen?'
            : room.user.ready
            ? 'Wacht op andere spelers…'
            : 'Klaar om te spelen?'}
        </Text>
        <Button
          onClick={() => {
            room.user.toggleReady()
          }}
          sx={{
            color: !room.isEmpty && room.user.ready ? 'primary' : 'white',
            bg: !room.isEmpty && room.user.ready ? 'white' : 'primary'
          }}
        >
          {!room.isEmpty && room.user.ready
            ? 'Ik ben er nog niet klaar voor'
            : room.isEmpty || room.everybodyReady
            ? 'Start het spel!'
            : 'Ik ben er klaar voor!'}
        </Button>
      </Grid>
    </Grid>
  )
})
