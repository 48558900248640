import { RoomInstance } from '@stores/models/Room'
import {
  types,
  Instance,
  getEnv,
  getRoot,
  onPatch,
  IDisposer
} from 'mobx-state-tree'
import { values } from 'mobx'
import sum from 'lodash/sum'
import range from 'lodash/range'
import pull from 'lodash/pull'
import flatten from 'lodash/flatten'
import cloneDeep from 'lodash/cloneDeep'
import uniq from 'lodash/uniq'

import { RoomEnv } from '@stores'
import { DiceInstance, GameInstance, GameState } from '@stores/models/Game'

const sumReducer = (accumulator: number, currentValue: WordInstance) => {
  return accumulator + (currentValue.points || 0)
}

const Word = types
  .model('Word', {
    word: types.string,
    validated: false,
    valid: types.boolean,
    duplicate: false,
    highlights: types.array(types.number)
  })
  .views((self) => ({
    get points() {
      return self.valid && !self.duplicate && self.validated
        ? self.word.length - 2
        : 0
    }
  }))
  .actions((self) => {
    return {
      validate(validated: boolean) {
        self.validated = validated
      },
      setDuplicate() {
        self.duplicate = true
      }
    }
  })

export const User = types
  .model('User', {
    id: types.identifier,
    name: types.optional(types.string, 'Anoniem'),
    color: types.optional(types.string, 'blue'),
    emoji: types.string,
    ready: false,
    joined: false,
    words: types.map(Word),
    previousScores: types.array(types.number),
    longestWord: types.optional(types.string, '')
  })
  .views((self) => ({
    get isReturning() {
      return self.name !== 'Anoniem'
    },
    get isLeopard() {
      return self.name.toLowerCase() === 'roos'
    },
    get wordsArray() {
      return values(self.words)
    },
    get roundPoints() {
      return this.wordsArray.reduce(sumReducer, 0)
    },
    get totalPoints() {
      return this.roundPoints + sum(self.previousScores)
    },
    get validatedWords() {
      return this.wordsArray.filter((w) => w.validated).map((w) => w.word)
    },
    get longestValidatedWord() {
      return this.validatedWords.length > 0
        ? this.validatedWords
            .slice()
            .sort((a: string, b: string) => b.length - a.length)[0]
        : ''
    },
    get currentLongestWord() {
      return this.longestValidatedWord.length > self.longestWord.length
        ? this.longestValidatedWord
        : self.longestWord
    }
  }))

interface IPath {
  start: number
  dices: number[]
  step: number
  dead: boolean
  availableIndices: number[]
}

const checkWord = (initialDices: DiceInstance[], letters: string[]) => {
  const paths: Map<number, IPath> = new Map()

  const followPath = (path: IPath, dice: DiceInstance) => {
    const next = dice.neighbours.filter(
      (dice: DiceInstance) =>
        dice.activeLetter === letters[path.step + 1] &&
        path.availableIndices.includes(dice.positionIndex)
    )
    if (next.length === 0) {
      if (path.dices.length !== letters.length) path.dead = true
    } else {
      const original = cloneDeep(path)
      next.forEach((dice: DiceInstance, i: number) => {
        if (i === 0) {
          pull(path.availableIndices, dice.positionIndex)
          path.dices.push(dice.positionIndex)
          path.step++
          followPath(path, dice)
        } else {
          const clone = cloneDeep(original)
          pull(clone.availableIndices, dice.positionIndex)
          clone.dices.push(dice.positionIndex)
          clone.step++
          const nextIndex = paths.size + 1
          paths.set(nextIndex, clone)
          followPath(paths.get(nextIndex), dice)
        }
      })
    }
  }

  initialDices.forEach((dice, i) => {
    paths.set(i, {
      start: dice.positionIndex,
      dead: false,
      step: 0,
      availableIndices: pull(range(16), dice.positionIndex),
      dices: [dice.positionIndex]
    })
    followPath(paths.get(i), dice)
  })
  return Array.from(paths.values()).filter((p) => !p.dead)
}

export const LocalUser = User.actions((self) => {
  let disposer: IDisposer
  let allowPatch = true

  return {
    enablePatching() {
      allowPatch = true
    },
    disablePatching() {
      allowPatch = false
    },
    checkDuplicates() {
      const room = getRoot<RoomInstance>(self)
      room.users.forEach(({ words }) => {
        words.forEach(({ word }) => {
          if (self.words.has(word)) self.words.get(word).setDuplicate()
        })
      })
    },
    addWord(word: string) {
      const game: GameInstance = getRoot<RoomInstance>(self).game
      if (game.state !== GameState.STARTED) return
      if (!self.words.has(word)) {
        const split = word.toUpperCase().split('')
        const solved = checkWord(
          game.dices.filter((d) => d.activeLetter === split[0]),
          split
        )
        if (solved.length) {
          const highlights = flatten(solved.map((p) => p.dices))
          self.words.set(word, {
            word,
            valid: true,
            highlights: uniq(highlights)
          })
        } else {
          self.words.set(word, { word, valid: false, highlights: [] })
        }
      }
    },
    setName(name: string) {
      self.name = name.trim()
    },
    toggleReady() {
      self.ready = !self.ready
    },
    saveLongestWord() {
      if (self.longestValidatedWord.length > self.longestWord.length) {
        self.longestWord = self.longestValidatedWord
      }
    },
    clearWords() {
      this.saveLongestWord()
      self.previousScores.push(self.roundPoints)
      self.words.clear()
    },
    afterAttach() {
      const env = getEnv<RoomEnv>(self)
      disposer = onPatch(self, (patch) => {
        if (allowPatch) env.socket.send('patch', patch)
      })
    },
    beforeDestroy() {
      disposer && disposer()
    }
  }
})

export interface UserInstance extends Instance<typeof User> {}
export interface LocalUserInstance extends Instance<typeof LocalUser> {}
export interface WordInstance extends Instance<typeof Word> {}

export function LateUser() {
  return User
}
