/** @jsx jsx */
import * as React from 'react'
import { Box, Flex, Grid, jsx } from 'theme-ui'
import { observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'

import { useStore } from '@stores/useStore'
import { GameState } from '@stores/models/Game'
import { UserInstance } from '@stores/models/User'

const Word = observer(({ word, editMode, setHighlighted }) => (
  <Box
    onMouseEnter={
      word.valid
        ? () => setHighlighted(getSnapshot(word.highlights))
        : undefined
    }
    onMouseLeave={() => setHighlighted([])}
    sx={{ mb: 1 }}
  >
    <label
      sx={{
        textDecoration: word.valid && !word.duplicate ? 'unset' : 'line-through'
      }}
    >
      <input
        type="checkbox"
        checked={word.validated}
        disabled={!editMode || !word.valid || word.duplicate}
        onChange={(ev) => word.validate(ev.target.checked)}
      />
      {word.word}{' '}
      {word.valid && !word.duplicate && (
        <span sx={{ color: word.validated ? 'grays.300' : 'grays.200' }}>
          ({word.points})
        </span>
      )}
      {word.duplicate && <span sx={{ color: 'grays.200' }}>(dubbel)</span>}
    </label>
  </Box>
))

export const WordListView = observer(
  React.forwardRef(
    ({ user, reverse }: { user: UserInstance; reverse?: boolean }, ref) => {
      const room = useStore()
      const { game } = room

      const editMode =
        game.state === GameState.ENDED && room.user.id === user.id

      return (
        <Grid
          sx={{
            p: 2,
            gridTemplateRows: 'auto',
            columnGap: 0,
            rowGap: 0
          }}
          ref={ref}
        >
          <Flex
            sx={{
              alignContent: 'flex-start',
              flexDirection: reverse ? ['column-reverse', 'column'] : 'column'
            }}
          >
            {user.wordsArray.map((word, i) => (
              <Word
                key={`word-${i}`}
                word={word}
                setHighlighted={game.setHighlighted}
                editMode={editMode}
              />
            ))}
          </Flex>
        </Grid>
      )
    }
  )
)
