import * as React from 'react'
import { onAction, SnapshotOut } from 'mobx-state-tree'

import { FriendlyWebSocket } from '@utils/FriendlySocket'
import { LocalUser, LocalUserInstance } from '@stores/models/User'
import { Room, RoomInstance } from '@stores/models/Room'
import { getRandomEmoji } from '@utils/people'

export type RoomEnv = {
  socket: FriendlyWebSocket
  user: LocalUserInstance
}

const LOCALSTORAGE_ID = 'boggle'

export const createStore = (
  uid: string,
  reconnected: boolean,
  roomId: string,
  socket: FriendlyWebSocket
): RoomInstance => {
  let initialUserState: Partial<Omit<
    SnapshotOut<LocalUserInstance>,
    'id' & 'emoji'
  >> = {}
  const emoji = getRandomEmoji().name
  try {
    const storedUserState = localStorage.getItem(LOCALSTORAGE_ID)
    if (storedUserState) {
      const json = JSON.parse(storedUserState)
      if (LocalUser.is({ id: uid, emoji, ...json })) initialUserState = json
    }
  } catch (e) {
    console.log('No initial user state found')
  }

  const user = LocalUser.create({
    id: uid,
    emoji,
    ...initialUserState
  })

  const env: RoomEnv = { user, socket }

  onAction(user, (call) => {
    if (call.name === 'setName' && call.args) {
      try {
        localStorage.setItem(
          LOCALSTORAGE_ID,
          JSON.stringify({ name: call.args[0] })
        )
      } catch (e) {
        console.warn('Could not persist user to local storage.')
      }
    }
  })

  console.log('creating room store')
  return Room.create({ id: roomId, reconnected, user }, env)
}

export const RoomContext = React.createContext<null | RoomInstance>(null)
