/** @jsx jsx */
import * as React from 'react'
import { Grid, Text, jsx } from 'theme-ui'
import { observer } from 'mobx-react'

import CopyRoomLink from '@components/CopyRoomLink'
import { GameState } from '@stores/models/Game'
import { useStore } from '@stores/useStore'
import { WordListView } from '@components/WordListView'
import { PointTotals } from '@components/PointTotals'
import { InputWordForm } from '@components/forms/InputWordForm'

export const Sidebar = observer(
  ({ children, ...rest }: { children: React.ReactNode }) => {
    const room = useStore()
    const { game, user } = room

    const ended = game.state === GameState.ENDED
    const idle =
      game.state === GameState.IDLE || game.state === GameState.WAITING

    return (
      <Grid
        sx={{
          gridTemplateColumns: 'auto',
          gridTemplateRows: 'auto',
          minHeight: 180
        }}
        {...rest}
      >
        <Grid
          sx={{
            gridTemplateAreas: ended
              ? [`'form' 'words' 'points'`, `'words' 'points' 'form'`]
              : [`'form' 'words'`, `'words' 'form'`],
            gridTemplateRows: ended
              ? ['max-content 1fr max-content', '1fr max-content max-content']
              : ['max-content 1fr', '1fr max-content'],
            position: 'relative',
            width: '100%',
            height: '100%',
            gridGap: 0,
            border: (theme) => `1px solid ${theme.colors.grays[200]}`,
            borderRadius: 3,
            bg: ended ? 'grays.100' : 'unset',
            transition: 'background 0.3s ease'
          }}
        >
          <WordListView user={user} reverse sx={{ gridArea: 'words' }} />

          {ended && <PointTotals user={user} sx={{ gridArea: 'points' }} />}

          <InputWordForm sx={{ gridArea: 'form' }} />

          {idle && (
            <Grid
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: 3,
                bg: 'rgba(255,255,255,0.85)',
                backdropFilter: 'blur(10px)'
              }}
            >
              <Grid
                sx={{
                  justifyContent: 'center',
                  gridRowGap: 3,
                  textAlign: 'center',
                  p: 4
                }}
              >
                <Text>
                  {room.isEmpty
                    ? 'Dit spel heeft nog geen tegenspelers! Deel deze URL om mensen uit te nodigen:'
                    : 'Deel deze URL om meer mensen uit te nodigen:'}
                </Text>
                <CopyRoomLink />
              </Grid>
            </Grid>
          )}
        </Grid>
        {children}
      </Grid>
    )
  }
)
