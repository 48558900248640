/** @jsx jsx */
import { jsx, Text, Grid } from 'theme-ui'
import { observer } from 'mobx-react'

import { useStore } from '@stores/useStore'
import { GameState } from '@stores/models/Game'
import { Button } from '@components/Button'

export const CanvasEndedState = observer(() => {
  const room = useStore()
  const { game } = room

  return game.state !== GameState.ENDED ? null : (
    <Grid
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 3
      }}
    >
      <Grid
        sx={{
          justifyContent: 'center',
          gridRowGap: 3,
          textAlign: 'center',
          p: 4
        }}
      >
        {!room.isEmpty && (
          <Text
            sx={{ color: 'white', textShadow: '1px 1px 3px rgba(0,0,0,0.5)' }}
          >
            {room.user.ready ? 'Wacht op andere spelers…' : ''}
          </Text>
        )}
        <Button
          onClick={() => {
            room.user.toggleReady()
          }}
          sx={{
            color: !room.isEmpty && room.user.ready ? 'primary' : 'white',
            bg: !room.isEmpty && room.user.ready ? 'white' : 'primary'
          }}
        >
          {!room.isEmpty && room.user.ready
            ? 'Ik ben er nog niet klaar voor'
            : room.isEmpty || room.everybodyReady
            ? 'Start volgende ronde!'
            : 'Volgende ronde!'}
        </Button>
      </Grid>
    </Grid>
  )
})
