/** @jsx jsx */
import { Color, CanvasTexture, MeshStandardMaterial, Mesh } from 'three'
import * as React from 'react'
import { jsx, Grid, Box, AspectRatio } from 'theme-ui'
import { Canvas as R3FCanvas, useThree } from 'react-three-fiber'
import { animated, useSprings, useSpring } from 'react-spring/three'
import { observer } from 'mobx-react'
import shuffle from 'lodash/shuffle'

import { useStore } from '@stores/useStore'
import { GameState, DiceInstance } from '@stores/models/Game'
import { CanvasIdleState } from '@components/CanvasIdleState'
import { CanvasEndedState } from '@components/CanvasEndedState'
import { CanvasCountdownState } from '@components/CanvasCountdownState'
import { CanvasWaitingState } from '@components/CanvasWaitingState'

import { fontStack } from '../gatsby-plugin-theme-ui'
import { possibleRotations, positions } from '@stores/models/Game'

const Dice = observer(
  ({ size, dice, ...rest }: { size: number; dice: DiceInstance }) => {
    const { invalidate } = useThree()
    const ref = React.useRef<Mesh>()
    const textures = React.useMemo(() => {
      return dice.sides.map((side) => {
        const canvas = document.createElement('canvas')
        const width = 256
        const height = width
        canvas.width = width
        canvas.height = width
        const ctx = canvas.getContext('2d')
        ctx.fillStyle = '#ffffff'
        ctx.fillRect(0, 0, width, height)
        const fontSize = 220
        ctx.font = `500 ${fontSize}px ${fontStack}`
        ctx.fillStyle = 'black'
        ctx.textAlign = 'center'
        ctx.fillText(side, width * 0.5, height * 0.5 + fontSize * 0.366)
        const texture = new CanvasTexture(canvas)
        texture.anisotropy = 8
        // texture.encoding = sRGBEncoding
        return texture
      })
    }, [dice.sides])

    const materials = React.useMemo(() => {
      return textures.map(
        (texture) =>
          new MeshStandardMaterial({
            map: texture
          })
      )
    }, [textures])

    React.useEffect(() => {
      ref.current.material.forEach((m: MeshStandardMaterial) => {
        m.color = new Color(dice.highlighted ? '#E6BE17' : 'white')
      })
      invalidate()
    }, [dice.highlighted])

    return (
      <animated.mesh ref={ref} material={materials} {...rest}>
        <boxBufferGeometry attach="geometry" args={[size, size, size]} />
      </animated.mesh>
    )
  }
)

const Camera = ({ targetSize }: { targetSize: number }) => {
  const { camera, viewport } = useThree()
  React.useEffect(() => {
    const f = Math.min(viewport.width, viewport.height) / (targetSize + 1.5)
    camera.position.z = camera.position.z / f
  }, [viewport.width, viewport.height])
  return null
}

export const Canvas = observer((props) => {
  const { game } = useStore()
  const { dices, state } = game
  const size = 1
  const margin = 0.025
  const width = 4 * (size + margin + margin)

  const lightSpring = useSpring({
    intensity:
      state === GameState.IDLE || state === GameState.WAITING
        ? 0
        : state === GameState.ENDED
        ? 0.25
        : 1
  })

  const shuffled = React.useRef(shuffle(positions))
  const reshuffle = () => {
    shuffled.current = shuffle(positions)
  }

  const springs = useSprings(
    dices.length,
    dices.map((dice: DiceInstance, index: number) => ({
      from: {
        position: dice.position.slice(),
        rotation: dice.rotation.slice()
      },
      to: async (next) => {
        while (game.state === GameState.COUNTDOWN) {
          if (index === 0) reshuffle()
          await next({
            position: shuffled.current[index],
            rotation:
              possibleRotations[
                Math.floor(Math.random() * possibleRotations.length)
              ],
            config: {
              tension: 280,
              friction: 24
            }
          })
        }
        await next({
          position: dice.position.slice(),
          rotation: dice.rotation.slice()
        })
      },
      config: {
        tension: 280,
        friction: 24
      }
    }))
  )

  return (
    <Box {...props}>
      <Grid
        sx={{
          position: 'sticky',
          top: 60,
          height: ['unset', 'calc(100vh - 120px)'],
          '& > div': {
            width: '100%',
            m: '0 auto',
            minHeight: 160
          }
        }}
      >
        <AspectRatio
          ratio={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <R3FCanvas
            shadowMap
            invalidateFrameloop
            camera={{ position: [0, 0, 4] }}
            colorManagement
            gl={{ antialias: false }}
            pixelRatio={Math.min(1.5, window.devicePixelRatio)}
          >
            <animated.pointLight
              position={[-3, -3, 20]}
              intensity={lightSpring.intensity.to([0, 1], [0.02, 0.5])}
              castShadow
            />
            <animated.spotLight
              intensity={lightSpring.intensity.to([0, 1], [0.02, 0.4])}
              position={[3, 3, 20]}
              angle={0.2}
              penumbra={1}
              castShadow
            />
            <Camera targetSize={width} />
            {springs.map((props, i) => (
              <Dice {...props} key={`dice-${i}`} size={size} dice={dices[i]} />
            ))}
          </R3FCanvas>
          <CanvasCountdownState />
          <CanvasIdleState />
          <CanvasWaitingState />
          <CanvasEndedState />
        </AspectRatio>
      </Grid>
    </Box>
  )
})
