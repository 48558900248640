/** @jsx jsx */
import { Grid, jsx } from 'theme-ui'
import { observer } from 'mobx-react'
import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'

export const RoomFrame = observer(
  ({ id, children }: { id?: string; children: React.ReactNode }) => (
    <Layout>
      {id && <SEO title={id} description="Speel mee in dit boggle spel!" />}
      <Grid
        sx={{
          justifyContent: 'center',
          gridRowGap: 6,
          p: 3,
          minHeight: '100%',
          alignItems: 'center'
        }}
      >
        <Grid
          sx={{
            p: 4,
            borderRadius: 4,
            textAlign: 'center',
            border: (theme) => `1px solid ${theme.colors.grays[400]}`,
            width: '100%',
            maxWidth: '26em'
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Layout>
  )
)
