import * as React from 'react'

import { RoomContext } from '@stores'

export const useStore = () => {
  const store = React.useContext(RoomContext)
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider.')
  }
  return store
}
