/** @jsx jsx */
import { jsx, Text, Grid, Flex } from 'theme-ui'
import { observer } from 'mobx-react'
import { alpha } from '@theme-ui/color'

import { useStore } from '@stores/useStore'
import { GameState } from '@stores/models/Game'

export const CanvasCountdownState = observer(() => {
  const room = useStore()
  const {
    game,
    game: { counter }
  } = room

  return game.state !== GameState.COUNTDOWN ? null : (
    <Grid
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 3
      }}
    >
      <Grid
        sx={{
          justifyContent: 'center',
          gridRowGap: 3,
          textAlign: 'center',
          p: 4
        }}
      >
        <Flex
          sx={{
            textAlign: 'center',
            py: 2,
            px: 3,
            borderRadius: 2,
            color: 'white',
            bg: alpha('grays.600', 0.8),
            fontSize: 6
          }}
        >
          <Text>{counter}</Text>
        </Flex>
      </Grid>
    </Grid>
  )
})
