/** @jsx jsx */
import { Flex, Grid, Text, jsx } from 'theme-ui'
import { observer } from 'mobx-react'
import { ellipsis } from 'polished'

import { useStore } from '@stores/useStore'
import { Toggle } from '@components/Toggle'

import { people } from '@utils/people'
import { UserInstance, LocalUserInstance } from '@stores/models/User'
import { GameInstance, GameState } from '@stores/models/Game'

const PointCounter = observer(({ user }: { user: UserInstance }) => (
  <Text>({user.totalPoints})</Text>
))

const User = observer(
  ({
    user,
    game,
    self
  }: {
    user: UserInstance | LocalUserInstance
    game?: GameInstance
    self?: boolean
  }) => (
    <Grid
      sx={{
        alignItems: 'center',
        p: 2,
        borderRadius: 4,
        mr: 2,
        mb: [1, 0],
        gridColumnGap: 1,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: self ? 'primary' : 'black',
        gridTemplateColumns: 'max-content 1fr max-content max-content',
        bg: self ? 'primary' : 'unset'
      }}
    >
      <span role="img">
        {user.isLeopard ? people['leopard'].char : people[user.emoji].char}
      </span>
      <Text sx={{ ...ellipsis() }}>{user.name}</Text>
      <PointCounter user={user} />
      <Toggle
        label={
          self && game
            ? game.state === GameState.IDLE
              ? 'Klaar om te spelen?'
              : 'Volgende ronde?'
            : undefined
        }
        checked={user.ready}
        onChange={self ? user.toggleReady : undefined}
        sx={{ color: self ? 'white' : 'grays.200' }}
      />
    </Grid>
  )
)

export const Users = observer((props) => {
  const { allUsers, user, game } = useStore()

  return (
    <Flex
      {...props}
      sx={{
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: ['wrap', 'nowrap']
      }}
    >
      <User user={user} self game={game} />
      {allUsers.map((u) => (
        <User key={`user-${u.id}`} user={u} />
      ))}
    </Flex>
  )
})
