import {
  getRoot,
  getEnv,
  getParent,
  types,
  Instance,
  cast,
  IDisposer
} from 'mobx-state-tree'
import { autorun } from 'mobx'
import sortBy from 'lodash/sortBy'

import { RoomEnv } from '@stores'
import { RoomInstance } from '@stores/models/Room'

export enum GameState {
  IDLE = 'IDLE',
  COUNTDOWN = 'COUNTDOWN',
  STARTED = 'STARTED',
  ENDED = 'ENDED',
  WAITING = 'WAITING'
}

function getPossibleRotations() {
  const halfPi = Math.PI * 0.5
  return [
    [0, -halfPi, 0], // 0 in dice
    [0, halfPi, 0], // 1 ...
    [halfPi, 0, 0], // 2
    [-halfPi, 0, 0], // 3
    [0, 0, 0], // 4
    [0, Math.PI, 0] // 5
  ]
}

function getGrid() {
  const columns = 4
  const rows = 4
  const z = 0
  const size = 1
  const margin = 0.025
  const totalSize = size + margin + margin
  const halfTotalSize = totalSize * 0.5
  const xOffset = columns * halfTotalSize
  const yOffset = rows * halfTotalSize
  const pos = []

  let y = 0
  for (let i = 0; i < rows; i++) {
    let x = 0
    for (let j = 0; j < columns; j++) {
      pos.push([x - xOffset + halfTotalSize, y - yOffset + halfTotalSize, z])
      x += totalSize
    }
    y += totalSize
  }
  return pos
}
export const possibleRotations = getPossibleRotations()
export const positions = getGrid()

// const diceSidesEnglish = [
//   ['R', 'I', 'F', 'O', 'B', 'X'],
//   ['I', 'F', 'E', 'H', 'E', 'Y'],
//   ['D', 'E', 'N', 'O', 'W', 'S'],
//   ['U', 'T', 'O', 'K', 'N', 'D'],
//   ['H', 'M', 'S', 'R', 'A', 'O'],
//   ['L', 'U', 'P', 'E', 'T', 'S'],
//   ['A', 'C', 'I', 'T', 'O', 'A'],
//   ['Y', 'L', 'G', 'K', 'U', 'E'],
//   ['Qu', 'B', 'M', 'J', 'O', 'A'],
//   ['E', 'H', 'I', 'S', 'P', 'N'],
//   ['V', 'E', 'T', 'I', 'G', 'N'],
//   ['B', 'A', 'L', 'I', 'Y', 'T'],
//   ['E', 'Z', 'A', 'V', 'N', 'D'],
//   ['R', 'A', 'L', 'E', 'S', 'C'],
//   ['U', 'W', 'I', 'L', 'R', 'G'],
//   ['P', 'A', 'C', 'E', 'M', 'D']
// ]

const diceSides = [
  ['K', 'Z', 'T', 'O', 'N', 'E'],
  ['M', 'I', 'R', 'S', 'O', 'A'],
  ['M', 'D', 'Q', 'O', 'B', 'J'],
  ['H', 'R', 'S', 'N', 'E', 'I'],
  ['N', 'U', 'J', 'E', 'G', 'Y'],
  ['E', 'A', 'W', 'I', 'O', 'A'],
  ['D', 'C', 'M', 'P', 'A', 'E'],
  ['T', 'E', 'V', 'I', 'G', 'N'],
  ['T', 'L', 'N', 'P', 'U', 'E'],
  ['R', 'X', 'F', 'I', 'K', 'A'],
  ['E', 'E', 'S', 'I', 'H', 'F'],
  ['H', 'A', 'R', 'E', 'C', 'S'],
  ['E', 'D', 'O', 'N', 'S', 'T'],
  ['G', 'W', 'U', 'L', 'E', 'R'],
  ['A', 'N', 'I', 'T', 'L', 'B'],
  ['E', 'N', 'A', 'D', 'V', 'Z']
]

const getTestDices = () => {
  const testSides = [
    ['L', 'Z', 'T', 'O', 'N', 'E'],
    ['E', 'I', 'R', 'S', 'O', 'A'],
    ['E', 'D', 'Q', 'O', 'B', 'J'],
    ['N', 'R', 'S', 'N', 'E', 'I'],
    ['C', 'U', 'J', 'E', 'G', 'Y'],
    ['T', 'A', 'W', 'I', 'O', 'A'],
    ['J', 'C', 'M', 'P', 'A', 'E'],
    ['F', 'E', 'V', 'I', 'G', 'N'],
    ['E', 'L', 'N', 'P', 'U', 'E'],
    ['J', 'X', 'F', 'I', 'K', 'A'],
    ['I', 'E', 'S', 'I', 'H', 'F'],
    ['T', 'A', 'R', 'E', 'C', 'S'],
    ['M', 'D', 'O', 'N', 'S', 'T'],
    ['C', 'W', 'U', 'L', 'E', 'R'],
    ['O', 'N', 'I', 'T', 'L', 'B'],
    ['U', 'N', 'A', 'D', 'V', 'Z']
  ]
  return testSides.map((sides, index) => {
    const randomRotationIndex = 0
    return {
      sides,
      positionIndex: index,
      position: positions[index],
      rotation: possibleRotations[randomRotationIndex],
      activeLetter: sides[randomRotationIndex]
    }
  })
}

export const getRandomDices = () => {
  return diceSides.map((sides, index) => {
    const randomRotationIndex = Math.floor(
      Math.random() * possibleRotations.length
    )
    return {
      sides,
      positionIndex: index,
      position: positions[index],
      rotation: possibleRotations[randomRotationIndex],
      activeLetter: sides[randomRotationIndex]
    }
  })
}

type ServerDiceData = {
  position: number
  rotation: number
}

const boundaryArray = [
  [null, null, null, null, null, null],
  [null, 12, 13, 14, 15, null],
  [null, 8, 9, 10, 11, null],
  [null, 4, 5, 6, 7, null],
  [null, 0, 1, 2, 3, null],
  [null, null, null, null, null, null]
]

const Dice = types
  .model('Dice', {
    sides: types.array(types.string),
    position: types.array(types.number),
    positionIndex: types.number,
    rotation: types.array(types.number),
    activeLetter: types.string
  })
  .views((self) => ({
    get highlighted() {
      const parent = getParent<GameInstance>(self, 2)
      return parent && parent.highlighted.includes(self.positionIndex)
    },
    get x() {
      return self.positionIndex % 4
    },
    get y() {
      return Math.floor(self.positionIndex / 4)
    },
    get neighbours() {
      const parent = getParent<GameInstance>(self, 2)
      if (!parent) return []
      const dices = parent.sortedDices
      const x = this.x + 1
      const y = 3 - this.y + 1
      const indices = []
      for (let i = x - 1; i <= x + 1; i++) {
        for (let j = y - 1; j <= y + 1; j++) {
          const index = boundaryArray[j][i]
          if (index !== null && index !== self.positionIndex) {
            indices.push(index)
          }
        }
      }
      return indices.map((index) => dices[index])
    },
    get possibleNextLetters() {
      return this.neighbours.map((d) => d.activeLetter)
    }
  }))
  .actions((self) => ({
    update(data: ServerDiceData) {
      self.positionIndex = data.position
      self.position = cast(positions[data.position])
      self.rotation = cast(possibleRotations[data.rotation])
      self.activeLetter = self.sides[data.rotation]
    }
  }))

const ServerDice = types.model({
  position: 0,
  rotation: 0
})

export const Game = types
  .model('Game', {
    state: types.optional(
      types.enumeration<GameState>('GameState', Object.values(GameState)),
      GameState.IDLE
    ),
    duration: 90 * 1000, // 90 seconds
    countdownDuration: 3 * 1000, // 3 seconds
    serverDices: types.array(ServerDice),
    dices: types.optional(types.array(Dice), getRandomDices()),
    highlighted: types.array(types.number)
  })
  .volatile((): { counter: number } => ({
    counter: 0
  }))
  .views((self) => ({
    get sortedDices(): DiceInstance[] {
      return sortBy(self.dices, 'positionIndex')
    },
    get activeLetters() {
      return this.sortedDices.map((dice) => dice.activeLetter)
    }
  }))
  .actions((self) => {
    let timer: number
    let disposer: IDisposer
    return {
      setHighlighted(positions: number[]) {
        self.highlighted = cast(positions)
      },
      updateCounter() {
        if (self.counter > 0) {
          self.counter = self.counter - 1
        } else {
          clearInterval(timer)
        }
      },
      setCounter(duration: number) {
        clearInterval(timer)
        self.counter = Math.floor(duration / 1000)
        timer = setInterval(this.updateCounter, 1000)
      },
      countdown() {
        console.log('countdown started')
        const room = getRoot<RoomInstance>(self)
        room.user.clearWords()
        this.setHighlighted([])
        this.setCounter(self.countdownDuration)
      },
      start() {
        console.log('game started')
        this.setCounter(self.duration)
      },
      end() {
        this.setCounter(0)
        getEnv<RoomEnv>(self).user.checkDuplicates()
      },
      afterCreate() {
        disposer = autorun(() => {
          self.serverDices.map((dice, i) => {
            self.dices[i].update(dice)
          })
        })
      },
      setElapsedTime(time: number) {
        if (self.state === GameState.STARTED) {
          this.setCounter(self.duration - time)
        }
        if (self.state === GameState.COUNTDOWN) {
          this.setCounter(self.countdownDuration - time)
        }
      },
      beforeDestroy() {
        disposer()
        clearInterval(timer)
      }
    }
  })

export interface GameInstance extends Instance<typeof Game> {}
export interface DiceInstance extends Instance<typeof Dice> {}
