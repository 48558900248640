import verbs from './verbs.json'
import nouns from './nouns.json'
import adjectives from './adjectives.json'

const getRandomFromArray = (array: string[]) => {
  return array[Math.floor(Math.random() * array.length)]
}

const shuffle = (array: string[]) => {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

type HRIDOptions = {
  glue?: string
  maxWordLength?: number
}

class Dictionary {
  words: string[]

  constructor(
    words: string[] = [],
    options: HRIDOptions = { maxWordLength: undefined }
  ) {
    if (options.maxWordLength === undefined) {
      this.words = words
    } else {
      this.words = words.filter(
        (w) => w.length <= (options.maxWordLength || Infinity)
      )
    }
  }

  getRandomWord() {
    return getRandomFromArray(this.words)
  }
}

type GeneratorOptions = {
  glue: string
}

class Generator {
  private dictionaries: Dictionary[]
  private glue: string

  constructor(options: HRIDOptions = { glue: '-' }) {
    this.dictionaries = []
    this.glue = options.glue || '-'
  }

  getId(length: number) {
    if (length === 0) throw new Error('Minimal length for a valid id is 1.')
    if (this.dictionaries.length === 0)
      throw new Error('No dictionaries available.')
    if (this.dictionaries.length === 1)
      return this.dictionaries[0].getRandomWord()

    let probe
    const tries = 10
    const used = new Map()

    const seq: string[] = []
    let dictCounter = 0
    for (let i = 0; i < length; i++) {
      let counter = 0
      while (counter < tries) {
        counter++
        if (!used.has((probe = this.dictionaries[dictCounter].getRandomWord())))
          break
      }

      if (counter === tries || !probe)
        throw new Error('Too many tries to find a unique word')

      used.set(probe, true)
      seq.push(probe)
      dictCounter =
        dictCounter - 1 > this.dictionaries.length ? 0 : dictCounter++
    }

    return shuffle(seq).join(this.glue)
  }

  load(dictionary: Dictionary) {
    this.dictionaries.push(dictionary)
  }
}

const createGenerator = (dictionaries: Dictionary[], options?: HRIDOptions) => {
  const generator = new Generator(options)

  dictionaries.forEach((dictionary) => {
    generator.load(dictionary)
  })

  return generator
}

function HRID(options?: HRIDOptions) {
  const dictionaries = [nouns, verbs, adjectives].map(
    (words) => new Dictionary(words, options)
  )
  const _generator = createGenerator(dictionaries, options)
  return (length = 3) => _generator.getId(length)
}

export default HRID
