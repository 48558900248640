/** @jsx jsx */
import * as React from 'react'
import { Grid, jsx } from 'theme-ui'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { useSpring, a } from 'react-spring'

import { SEO } from '@components/SEO'
import Logo from '@components/Logo'
// import { Logo } from '@components/Logo2'
import { Button } from '@components/Button'
import HRID from '@utils/hrid'

export const generateRandomName = HRID({ maxWordLength: 7 })

const Home = (props: RouteComponentProps) => {
  const { x } = useSpring({
    from: { x: 0 },
    to: async (next) => {
      while (1) {
        await next({ x: 6 })
        await next({ x: 0 })
      }
    },
    config: { tension: 400, friction: 22, bound: 2 }
  })

  return (
    <React.Fragment>
      <SEO />
      <Grid
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          gridTemplateColumns: '1fr',
          gridTemplateRows: 'auto auto',
          minHeight: '100%',
          p: 3,
          gridRowGap: 6
        }}
      >
        <Logo sx={{ width: '90vmin', mx: 'auto', alignSelf: 'flex-end' }} />
        <Button
          variant="dark"
          onClick={() => navigate(`/${generateRandomName()}`)}
          sx={{
            alignSelf: 'flex-start',
            justifySelf: 'center',
            my: 3,
            fontSize: 4,
            px: 4,
            color: 'black',
            bg: 'unset'
          }}
        >
          Start
          <a.span sx={{ display: 'inline-block' }} style={{ x }}>
            →
          </a.span>
        </Button>
      </Grid>
    </React.Fragment>
  )
}

export default Home
