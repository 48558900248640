/** @jsx jsx */
import { jsx, Close } from 'theme-ui'
import * as React from 'react'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { animated, useTransition } from 'react-spring'
import '@reach/dialog/styles.css'

const AnimatedDialogOverlay = animated(DialogOverlay)
const AnimatedDialogContent = animated(DialogContent)

const Modal = ({
  children,
  showDialog,
  onDismiss
}: {
  showDialog: boolean
  children: React.ReactNode
  onDismiss: () => void
}) => {
  const transition = useTransition(showDialog, {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
    config: {
      tension: 280
    }
  })

  return transition(
    ({ opacity, y }, item) =>
      item && (
        <AnimatedDialogOverlay
          style={{ opacity }}
          onDismiss={onDismiss}
          sx={{ zIndex: 800 }}
        >
          <AnimatedDialogContent
            sx={{
              '&[data-reach-dialog-content]': {
                borderRadius: 0,
                p: 0,
                m: 'auto',
                background: 'none',
                width: '90%',
                maxWidth: 600,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                maxHeight: '100%'
              }
            }}
            style={{
              y
            }}
            aria-label="Spelregels"
          >
            <div
              sx={{
                m: 'auto',
                width: '100%'
              }}
            >
              <div
                sx={{
                  background: 'white',
                  m: '1em 0',
                  position: 'relative'
                }}
              >
                <Close
                  onClick={onDismiss}
                  sx={{ position: 'absolute', top: 3, right: 3 }}
                />
                {children}
              </div>
            </div>
          </AnimatedDialogContent>
        </AnimatedDialogOverlay>
      )
  )
}

export default Modal
