/** @jsx jsx */
import * as React from 'react'
import { Spinner, Grid, Heading, Text, jsx } from 'theme-ui'
import { RouteComponentProps } from '@reach/router'
import { observer } from 'mobx-react'

import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import JoinRoomForm from '@components/forms/JoinRoomForm'
import ErrorBoundary from '@components/ErrorBoundary'
import { RoomFrame } from '@components/RoomFrame'
import { Canvas } from '@components/Canvas'
import { Users } from '@components/Users'
import { Sidebar } from '@components/Sidebar'
import { generateRandomName } from '@components/Home'
import { Button } from '@components/Button'
import { UserResultList } from '@components/UserResultList'

import { GameState } from '@stores/models/Game'
import { RoomState } from '@stores/models/Room'
import { useStore } from '@stores/useStore'
import { RoomProvider } from '@stores/RoomProvider'
import { Timer } from '@components/Timer'

const Room = observer(function Room({
  navigate
}: RouteComponentProps<{
  location: { state?: { action?: string } }
}>) {
  const room = useStore()

  if (room.state === RoomState.WAITING_TO_JOIN) {
    return (
      <Layout>
        <Grid
          sx={{
            justifyContent: 'center',
            minHeight: '100%',
            alignItems: 'center'
          }}
        >
          <Spinner />
        </Grid>
      </Layout>
    )
  }

  if (room.state === RoomState.REQUIRES_ACTION) {
    if (room.full) {
      return (
        <RoomFrame id={room.id}>
          <Heading>Helaas!</Heading>
          <Text>Helaas! Deze boggle kamer zit vol.</Text>
          <Button
            onClick={() => navigate(`/${generateRandomName()}`)}
            sx={{
              alignSelf: 'flex-start',
              justifySelf: 'center',
              px: 4
            }}
          >
            Start een nieuw spel→
          </Button>
        </RoomFrame>
      )
    }

    return (
      <RoomFrame id={room.id}>
        <Heading>
          {room.user.isReturning
            ? `Welkom terug ${room.user.name}!`
            : room.exists
            ? 'Meespelen'
            : 'Spel starten'}
        </Heading>
        <Text>
          {room.exists ? (
            <React.Fragment>
              Je gaat meespelen in het het boggle spel
              <br />“{room.id}”
            </React.Fragment>
          ) : (
            `Je gaat een nieuw boggle spel starten`
          )}
        </Text>
        <JoinRoomForm />
      </RoomFrame>
    )
  }

  return (
    <Layout>
      <SEO title={room.id} />
      <Grid
        sx={{
          gridTemplateAreas: [
            `'canvas' 'sidebar' 'users'`,
            `'users users' 'sidebar canvas'`
          ],
          gridTemplateColumns: ['auto', '18em minmax(50%, auto)'],
          gridTemplateRows: [
            'max-content auto max-content',
            'max-content auto'
          ],
          p: 4,
          pt: '3em'
        }}
      >
        <Users sx={{ gridArea: 'users' }} />
        <Canvas sx={{ gridArea: 'canvas' }} />
        <Sidebar sx={{ gridArea: 'sidebar' }}>
          {room.game.state === GameState.ENDED &&
            room.joinedUsers.map((user) => (
              <UserResultList key={`u-${user.id}`} user={user} />
            ))}
        </Sidebar>
      </Grid>
      <Timer />
    </Layout>
  )
})

const RoomWrapper = ({
  roomId,
  ...routeProps
}: RouteComponentProps<{ roomId: string }>) => {
  if (!roomId)
    return (
      <RoomFrame>
        <Text>
          Dit zou niet moeten gebeuren. We kunnen deze game niet vinden. Herlaad
          de pagina en probeer het nog een keer.
        </Text>
      </RoomFrame>
    )
  return (
    <ErrorBoundary>
      <RoomProvider id={roomId}>
        <Room {...routeProps} />
      </RoomProvider>
    </ErrorBoundary>
  )
}

export default RoomWrapper
