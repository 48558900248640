/** @jsx jsx */
import { Grid, Text, jsx } from 'theme-ui'
import { observer } from 'mobx-react'

import { WordListView } from '@components/WordListView'
import { people } from '@utils/people'
import { PointTotals } from '@components/PointTotals'
import { UserInstance } from '@stores/models/User'

export const UserResultList = observer(
  ({ user, ...rest }: { user: UserInstance }) => {
    return (
      <Grid
        {...rest}
        sx={{
          gridTemplateRows: 'max-content 1fr max-content',
          position: 'relative',
          width: '100%',
          height: '100%',
          gridGap: 0,
          border: (theme) => `1px solid ${theme.colors.grays[200]}`,
          borderRadius: 3,
          bg: 'grays.100',
          transition: 'background 0.3s ease'
        }}
      >
        <Grid
          gap={1}
          sx={{
            p: 2,
            gridTemplateColumns: 'max-content 1fr',
            borderBottom: (theme) => `1px solid ${theme.colors.grays[200]}`,
            bg: 'grays.100',
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            position: 'sticky',
            top: 0
          }}
        >
          <span role="img">
            {user.isLeopard ? people['leopard'].char : people[user.emoji].char}
          </span>
          <Text>{user.name}</Text>
        </Grid>

        <WordListView user={user} />

        <PointTotals
          user={user}
          sx={{
            borderBottomLeftRadius: 3,
            borderBottomRightRadius: 3
          }}
        />
      </Grid>
    )
  }
)
