/** @jsx jsx */
import { jsx, Text, Label } from 'theme-ui'
import { observer } from 'mobx-react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'

import { useStore } from '@stores/useStore'
import { Button } from '@components/Button'
import { Input } from '@components/forms/Input'

const nameSchema = yup.object().shape({
  name: yup.string().required()
})

const JoinRoomForm = observer(() => {
  const room = useStore()
  const { user } = room
  return (
    <Formik
      initialValues={{ name: user.name }}
      validationSchema={nameSchema}
      initialStatus={{}}
      onSubmit={async (values, { setStatus }) => {
        user.setName(values.name.trim())
        try {
          console.log('call join form')
          await room.join()
        } catch (e) {
          setStatus({
            error:
              'Het lukt niet te verbinden met het spel. Herlaad de pagina en probeer het opnieuw.'
          })
        }
      }}
    >
      {({ isSubmitting, status }) => (
        <Form
          sx={{
            display: 'grid',
            gridRowGap: 2
          }}
        >
          {status.error && <Text color="error">{status.error}</Text>}
          <Label htmlFor="name" sx={{ display: 'block', textAlign: 'center' }}>
            {user.isReturning ? 'Of vul een nieuwe naam in:' : 'Hoe heet je?'}
          </Label>
          <Field name="name">
            {({
              field: { onChange, ...fieldProps },
              form: { setFieldValue }
            }) => (
              <Input
                onChange={(ev) => {
                  setFieldValue(
                    fieldProps.name,
                    ev.target.value.replace(' ', '-')
                  )
                }}
                autoComplete="off"
                spellCheck="false"
                placeholder="Naam"
                maxLength={12}
                {...fieldProps}
              />
            )}
          </Field>
          <Button type="submit" disabled={status.error || isSubmitting}>
            {room.exists ? 'Speel mee!' : 'Start!'}
          </Button>
        </Form>
      )}
    </Formik>
  )
})

export default JoinRoomForm
