/** @jsx jsx */
import * as React from 'react'
import { Flex, Text, jsx } from 'theme-ui'
import { observer } from 'mobx-react'
import { alpha } from '@theme-ui/color'

import { GameState } from '@stores/models/Game'
import { useStore } from '@stores/useStore'

export const Timer = observer(() => {
  const {
    game,
    game: { counter }
  } = useStore()

  return (
    game.state === GameState.STARTED &&
    counter !== 0 && (
      <Flex
        sx={{
          textAlign: 'center',
          py: 2,
          px: 3,
          borderRadius: 2,
          bg: alpha('grays.100', 0.6),
          position: 'fixed',
          top: 2,
          right: 2,
          fontSize: 3
        }}
      >
        <Text>{counter}</Text>
      </Flex>
    )
  )
})
