/** @jsx jsx */
import { jsx, Grid, Text, Heading } from 'theme-ui'
import * as React from 'react'
import { Link } from 'gatsby'
import VisuallyHidden from '@reach/visually-hidden'

import Logo from '@components/Logo'
import Modal from '@components/Modal'
import { RoundButton } from '@components/Button'

const Rules = () => {
  const [show, setShow] = React.useState(false)
  return (
    <React.Fragment>
      <RoundButton
        size="1.5em"
        onClick={() => setShow((s) => !s)}
        sx={{
          position: 'fixed',
          top: 3,
          left: 4,
          p: 0,
          zIndex: 900
        }}
      >
        i
      </RoundButton>
      <Modal showDialog={show} onDismiss={() => setShow(false)}>
        <Grid sx={{ p: 5 }}>
          <Heading>Boggle spelregels</Heading>
          <Text>
            Vind zoveel mogelijk woorden in het raster. De letters van een
            gevonden woord dienen aan elkaar liggen (horizontaal, verticaal of
            diagonaal).
          </Text>
          <Text>Elk woord moet verder aan de volgende regels voldoen:</Text>
          <ul sx={{ m: 0, pl: '1.5em' }}>
            <li>Minimaal 3 letters.</li>
            <li>
              Elke letter uit het raster mag (per woord) maar één keer worden
              gebruikt.
            </li>
            <li>
              Vervoegingen van werkwoorden zijn niet toegestaan; alleen
              infinitieven (&lsquo;hele werkwoord&rsquo;) en voltooid
              deelwoorden (gemaakt, gelukt etc.).
            </li>
            <li>
              Het woord moet in een Nederlands woordenboek te vinden zijn.
              Spreek van tevoren af welke je aanhoudt (bijv.{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.vandale.nl/"
                sx={{ color: 'inherit', '&:hover': { textDecoration: 'none' } }}
              >
                vandale.nl
              </a>
              ).
            </li>
            <li>
              Afkortingen, eigennamen en woorden in andere talen zijn niet
              toegestaan.
            </li>
          </ul>
          <Text>
            Een ronde duurt 3 minuten. Woorden die door meerdere spelers zijn
            gevonden worden geschrapt. Na controle van de woorden en de geldige
            woorden aan te vinken, krijgt elke speler 1 punt voor een 3-letter
            woord, 2 punten voor een 4-letter woord, etc. De speler met de
            meeste punten wint de ronde.
          </Text>
        </Grid>
      </Modal>
    </React.Fragment>
  )
}

export const Layout = ({
  children,
  ...rest
}: {
  children: React.ReactNode
  onClick?: (ev: React.MouseEvent<HTMLElement>) => void
}) => {
  return (
    <main id="main" {...rest} sx={{ height: '100%' }}>
      <Link
        to="/"
        sx={{
          position: 'fixed',
          top: 3,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 700
        }}
      >
        <Logo
          skewed={false}
          sx={{
            width: '6.5em'
          }}
        />
        <VisuallyHidden>Home</VisuallyHidden>
      </Link>
      <Rules />
      {children}
    </main>
  )
}
