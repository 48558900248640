import {
  types,
  getEnv,
  applyPatch,
  Instance,
  applySnapshot,
  getSnapshot
} from 'mobx-state-tree'
import { values } from 'mobx'

import { RoomEnv } from '@stores'
import { User, LocalUser } from '@stores/models/User'
import { Game, GameState } from '@stores/models/Game'

/**
 * Possible room states:
 * - CONNECTED: user is connected and in the room
 * - WAITING_TO_JOIN: user initiated join, waiting for the 'joined' event from server
 * - REQUIRES_ACTION: room needs to be created or joined
 */
export enum RoomState {
  CONNECTED = 'CONNECTED',
  WAITING_TO_JOIN = 'WAITING_TO_JOIN',
  REQUIRES_ACTION = 'REQUIRES_ACTION'
}

export const Room = types
  .model('Room', {
    id: types.identifier,
    reconnected: false,
    users: types.map(User),
    exists: types.maybe(types.boolean),
    full: false,
    state: types.optional(
      types.enumeration<RoomState>('RoomState', Object.values(RoomState)),
      RoomState.WAITING_TO_JOIN
    ),
    user: LocalUser,
    game: types.optional(Game, {})
  })
  .views((self) => ({
    get allUsers() {
      return values(self.users)
    },
    get joinedUsers() {
      return this.allUsers.filter((user) => user.joined)
    },
    get isEmpty() {
      return this.allUsers.length === 0
    },
    get totalUsers() {
      return this.allUsers.length + 1
    },
    get everybodyReady() {
      return this.allUsers.every((user) => user.ready)
    }
  }))
  .actions((self) => {
    return {
      join() {
        if (
          [RoomState.CONNECTED, RoomState.WAITING_TO_JOIN].includes(self.state)
        ) {
          console.warn('already joining room')
          return
        }
        console.log('joining room')
        self.state = RoomState.WAITING_TO_JOIN
        getEnv(self).socket.send('join', {
          id: self.id,
          user: getSnapshot(self.user)
        })
      },
      createUser(data) {
        if (self.users.has(data.id))
          return applySnapshot(self.users.get(data.id), data)
        console.log('creating user', data)
        const newUser = User.create(data)
        self.users.put(newUser)
      },
      deleteUser(id: string) {
        if (self.users.has(id)) self.users.delete(id)
      },
      handleMessage(message: SocketMessage) {
        switch (message.type) {
          case 'updateGame':
            if (message.data.state !== self.game.state) {
              console.log(self.user.joined, self.game.state, message.data)
              if (self.user.joined) {
                applySnapshot(self.game, message.data)
                if (self.game.state === GameState.COUNTDOWN)
                  self.game.countdown()
                if (self.game.state === GameState.STARTED) self.game.start()
                if (self.game.state === GameState.ENDED) self.game.end()
              }
            }
            break
          case 'createUser':
            this.createUser(message.data)
            break
          case 'deleteUser':
            this.deleteUser(message.data.id)
            break
          case 'joined': {
            console.log('JOINED', message)
            self.state = RoomState.CONNECTED
            self.user.joined = message.data.joined
            applySnapshot(self.game, {
              ...message.data.game,
              state:
                message.data.game.state === GameState.IDLE
                  ? message.data.game.state
                  : GameState.WAITING
            })
            message.data.clients.forEach((user) => this.createUser(user))
            break
          }
          case 'reconnect': {
            console.log('RECONNECTED', message)
            self.state = RoomState.CONNECTED
            self.user.disablePatching()
            applySnapshot(self.user, message.data.user)
            self.user.enablePatching()
            applySnapshot(self.game, message.data.game)
            self.game.setElapsedTime(message.data.elapsedTime)
            message.data.clients.forEach((user) => this.createUser(user))
            break
          }
          case 'existance':
            console.log('EXISTANCE', message)
            if (message.data && message.data[self.id]) {
              const data = message.data[self.id]
              self.exists = data.exists
              self.full = data.full
            } else {
              console.log('room does not exist?')
              self.exists = false
            }
            self.state = RoomState.REQUIRES_ACTION
            break
          case 'patchUser':
            if (message.data.id === self.user.id) {
              applyPatch(self.user, message.data.patch)
            } else if (self.users.has(message.data.id)) {
              const user = self.users.get(message.data.id)
              applyPatch(user, message.data.patch)
            }
            break
          default:
            console.warn('Unknown message', message)
            return
        }
      },
      checkExistance() {
        getEnv<RoomEnv>(self).socket.send('checkRoomExistance', {
          id: self.id
        })
      }
    }
  })
  .actions((self) => {
    return {
      afterCreate() {
        getEnv<RoomEnv>(self).socket.on('message', self.handleMessage)
        if (!self.reconnected) self.checkExistance()
      },
      beforeDestroy() {
        const socket = getEnv(self).socket
        socket.off('message', self.handleMessage)
      }
    }
  })

export interface RoomInstance extends Instance<typeof Room> {}
