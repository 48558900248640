/** @jsx jsx */
import { Heading, Text, jsx } from 'theme-ui'
import { RouteComponentProps } from '@reach/router'

import { RoomFrame } from '@components/RoomFrame'
import { SEO } from '@components/SEO'
import { Button } from '@components/Button'

const NotFound = (props: RouteComponentProps) => (
  <RoomFrame>
    <SEO title="Pagina niet gevonden" />
    <Heading>Pagina niet gevonden</Heading>
    <Text>Helaas, deze pagina kunnen we niet vinden.</Text>
    <Button onClick={() => props.navigate('/')}>Ga naar home</Button>
  </RoomFrame>
)

export default NotFound
