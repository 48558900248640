/** @jsx jsx */
import { jsx, Grid } from 'theme-ui'
import { observer } from 'mobx-react'
import { CustomCheckboxContainer, CustomCheckboxInput } from '@reach/checkbox'
import { FiCircle, FiCheckCircle } from 'react-icons/fi'
import '@reach/checkbox/styles.css'

export const Toggle = observer(
  ({
    label,
    onChange,
    checked,
    disabled,
    ...rest
  }: {
    label: string
    onChange?: (value: boolean) => void
    checked: boolean
    disabled?: boolean
  }) => (
    <Grid
      as="label"
      sx={{
        gridColumnGap: label ? 2 : 0,
        alignItems: 'center',
        gridTemplateColumns: 'auto max-content',
        fontSize: 0
      }}
      {...rest}
    >
      {label}
      <CustomCheckboxContainer
        checked={checked}
        disabled={!onChange || disabled}
        onChange={(ev) => onChange && onChange(ev.target.checked)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '1em',
          height: '1em',
          borderRadius: '0.5em',
          '&:hover': {
            cursor: !onChange || disabled ? 'default' : 'pointer'
          },
          '&[data-reach-custom-checkbox-container]': {
            mr: 0
          }
        }}
      >
        <CustomCheckboxInput />
        <span
          aria-hidden
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '1em',
            height: '1em',
            borderRadius: '0.5em',
            color: 'black',
            fontSize: 2
          }}
        >
          {checked ? <FiCheckCircle width="1em" height="1em" /> : <FiCircle />}
        </span>
      </CustomCheckboxContainer>
    </Grid>
  )
)
