/** @jsx jsx */
import * as React from 'react'
import { Grid, jsx } from 'theme-ui'
import { observer, Observer } from 'mobx-react'
import { Formik, Form, Field } from 'formik'
import { useSpring, animated } from 'react-spring'
import * as yup from 'yup'
import { autorun } from 'mobx'

import { Input } from '@components/forms/Input'
import { Button } from '@components/Button'
import { GameState } from '@stores/models/Game'
import { useStore } from '@stores/useStore'

const AnimatedInput = animated(Input)

const initialValues = { word: '' }

const schema = yup.object().shape({
  word: yup.string().max(16)
})

const interpolateX = (x) =>
  x
    .to({
      range: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      output: [0, -1, 2, -4, 4, -4, 4, -4, 2, -1, 0]
    })
    .to((x) => `translateX(${x}px)`)

export const InputWordForm = observer((props) => {
  const room = useStore()
  const { game, user } = room
  const inputRef = React.useRef<HTMLInputElement>(null)

  const [shakeInputState, shakeInput] = React.useState(false)
  const { x } = useSpring({
    from: { x: 0 },
    x: shakeInputState ? 1 : 0,
    config: { duration: 600 }
  })

  React.useEffect(() => {
    const disposer = autorun(() => {
      if (
        game.state === GameState.COUNTDOWN ||
        game.state === GameState.STARTED
      ) {
        inputRef.current.focus()
      }
    })
    return () => disposer()
  }, [])

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        const value = values.word
        if (value === '' || value.length < 3) {
          shakeInput((s) => !s)
          setSubmitting(false)
        } else {
          user.addWord(value)
          setSubmitting(false)
          resetForm()
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form {...props}>
          <Grid
            gap={1}
            sx={{
              p: 2,
              gridTemplateColumns: '1fr min-content',
              borderTop: (theme) => [
                'none',
                `1px solid ${theme.colors.grays[200]}`
              ],
              borderBottom: (theme) => [
                `1px solid ${theme.colors.grays[200]}`,
                'none'
              ],
              bg: 'grays.100',
              borderTopLeftRadius: [3, 0],
              borderTopRightRadius: [3, 0],
              borderBottomLeftRadius: [0, 3],
              borderBottomRightRadius: [0, 3]
            }}
          >
            <Field name="word">
              {({
                field: { onChange, ...fieldProps },
                form: { setFieldValue }
              }) => (
                <Observer>
                  {() => (
                    <AnimatedInput
                      ref={inputRef}
                      {...fieldProps}
                      maxLength={16}
                      onChange={(ev) => {
                        setFieldValue(
                          fieldProps.name,
                          ev.target.value.trim().toLowerCase()
                        )
                      }}
                      sx={{
                        bg:
                          game.state === GameState.ENDED
                            ? 'grays.100'
                            : 'white',
                        borderColor: 'grays.200'
                      }}
                      style={{ transform: interpolateX(x) }}
                    />
                  )}
                </Observer>
              )}
            </Field>
            <Button
              type="submit"
              disabled={game.state !== GameState.STARTED || isSubmitting}
              sx={{ m: 0 }}
            >
              Toevoegen
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  )
})
