/** @jsx jsx */
import { Grid, Text, jsx } from 'theme-ui'
import { observer } from 'mobx-react'

import { UserInstance } from '@stores/models/User'

export const PointTotals = observer(
  ({ user, ...rest }: { user: UserInstance }) => (
    <Grid
      gap={1}
      sx={{
        p: 2,
        borderTop: (theme) => `1px solid ${theme.colors.grays[200]}`,
        bg: 'grays.100'
      }}
      {...rest}
    >
      <Text>Ronde punten: {user.roundPoints}</Text>
      <Text>Punten totaal: {user.totalPoints}</Text>

      <Text>
        {user.currentLongestWord
          ? `Langste woord: ${user.currentLongestWord}`
          : 'Nog geen langste woord'}
      </Text>
    </Grid>
  )
)
