/** @jsx jsx */
import { jsx, Heading, Alert, Text } from 'theme-ui'
import * as React from 'react'
import { navigate } from 'gatsby'

import { RoomFrame } from '@components/RoomFrame'
import { Button } from '@components/Button'

class ErrorBoundary extends React.PureComponent<{}, { error: Error | null }> {
  state = {
    error: null
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.error(info.componentStack)
  }

  render() {
    const { error } = this.state
    if (error) {
      return (
        <RoomFrame>
          <Heading>Hier ging iets mis…</Heading>
          <Text>
            Excuus, hier ging even iets mis. Ga terug naar home of herlaad de
            pagina om het opnieuw te proberen.
          </Text>
          <Alert variant="error">{error.message}</Alert>
          <Button onClick={() => navigate('/')}>Ga naar home</Button>
        </RoomFrame>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
