import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import shareImage from '@media/share.png'

const url = 'https://boggle.ingo.link'

export const SEO = ({
  description = '',
  meta = [],
  title
}: {
  title?: string
  meta?: { name: string; content: string }[]
  description?: string
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      defaultTitle={site.siteMetadata.title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        { name: 'author', content: site.siteMetadata.author },
        {
          property: `og:title`,
          content: title
            ? `${title} | ${site.siteMetadata.title}`
            : site.siteMetadata.title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: 'og:image',
          content: `${url}${shareImage}`
        },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '640' },
        {
          property: `og:type`,
          content: `website`
        },
        { property: 'og:url', content: 'https://boggle.ingo.link' },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: 'ingovalente'
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
    >
      {title && <title>{title}</title>}
    </Helmet>
  )
}
